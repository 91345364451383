@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&family=Open+Sans&family=Orbitron:wght@800&family=Play&display=swap');
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input {
  @apply focus:ring-0 focus:ring-transparent focus:ring-offset-transparent #{!important};
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.app-font {
  font-family: 'Orbitron', sans-serif;
}
em {
  font-style: normal;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.navLink-underline {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  padding-bottom: 0.3rem;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: #15cc2a;
    height: 4px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
}
.navLink-underline:hover:before,
.navLink-underline:focus:before,
.navLink-underline:active:before {
  left: 0;
  right: 0;
}

.bouncy {
  animation: bounce 3s infinite alternate;
}
.shake-infinite {
  animation: shakeItem 3s infinite alternate;
}
.zoom-infinite {
  animation: zoomItem 3s infinite alternate;
}
@keyframes bounce {
  to {
    transform: translate(0, -25px);
  }
}
@keyframes zoomItem {
  to {
    transform: scale(0.9);
  }
}
@keyframes shakeItem {
  to {
    transform: translate(-35px, 0);
  }
}

// AOS Style Import
@import 'node_modules/aos/src/sass/aos.scss';
